// Course action types and creators.
export const AI_TUTOR_LOGIN = 'routes/AiTutor/aiTutorLogin';
export const AI_TUTOR_LOGIN_ERROR = 'routes/AiTutor/aiTutorLoginError';
export const SET_AI_TUTOR_LOGIN_TOKEN = 'routes/AiTutor/setAiTutorLoginToken';
export const SET_AI_TUTOR_TEACHER_LOGIN_TOKEN = 'routes/AiTutor/setAiTutorTeacherLoginToken';
export const AI_TUTOR_REGISTER = 'routes/AiTutor/aiTutorRegister';
export const AI_TUTOR_REGISTER_ERROR = 'routes/AiTutor/aiTutorRegisterError';
export const UPDATE_USER_AI_TUTOR_ID = 'routes/AiTutor/updateUserAiTutorId';
export const UPDATE_USER_KIRA_POLICIES = 'routes/AiTutor/updateKiraPolicies';

export const aiTutorLogin = (username, userId, kiraInstance, intl) => ({
    type: AI_TUTOR_LOGIN,
    payload: {
        username,
        userId,
        kiraInstance,
    },
    intl,
});

export const aiTutorLoginError = (message) => ({
    type: AI_TUTOR_LOGIN_ERROR,
    payload: message,
});

export const setAiTutorLoginToken = (token) => ({
    type: SET_AI_TUTOR_LOGIN_TOKEN,
    payload: token,
});

export const setAiTutorTeacherLoginToken = (token) => ({
    type: SET_AI_TUTOR_TEACHER_LOGIN_TOKEN,
    payload: token,
});

export const aiTutorRegister = (username, userId, kiraInstance, intl) => ({
    type: AI_TUTOR_REGISTER,
    payload: {
        username,
        userId,
        kiraInstance,
    },
    intl,
});

export const aiTutorRegisterError = (message) => ({
    type: AI_TUTOR_REGISTER_ERROR,
    payload: message,
});

export const updateUserAiTutorId = (userId, aiTutorId, kiraInstance, intl) => ({
    type: UPDATE_USER_AI_TUTOR_ID,
    payload: {
        userId,
        aiTutorId,
        kiraInstance,
    },
    intl,
});

export const updateKiraPolicies = (userId, hasKiraPoliciesAccepted, intl) => ({
    type: UPDATE_USER_KIRA_POLICIES,
    payload: {
        userId,
        hasKiraPoliciesAccepted,
    },
    intl,
});
