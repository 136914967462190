// Global imports.
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import moment from 'moment';
import queryString from 'query-string';

// Stylesheets and media.
import logOut from '../views/icon/ic-log-out.svg';
import teacher from '../views/icon/ic-teacher.svg';
import localPin from '../views/icon/ic-local-pin.svg';
import laptop from '../views/icon/ic-laptop.svg';
import close from '../views/icon/ic-close.svg';
import burgerHolo from '../views/icon/ic-burger-holo.svg';
import starHolo from '../views/icon/ic-star-holo.svg';
import starFilled from '../views/icon/ic-star-filled.svg';
import home from '../views/icon/home.svg';
import group from '../views/icon/ic-group.svg';
import account from '../views/icon/ic-account.svg';
import checklist from '../views/icon/ic-checklist.svg';
import speechBubble from '../views/icon/ic-speech-bubble.svg';

// Utils, actions, sagas, etc.
import history from '../store/history';
import { corporateSubjects, GA, LOGIN_TOKEN_KEY, OLC_SESSION_ID_KEY } from './constants';
import { faqData } from '../routes/Faq/faqData';
import { storage } from './storage';
import { validationRules } from './validationRules';

const assets = [
    account,
    group,
    home,
    starHolo,
    starFilled,
    burgerHolo,
    close,
    laptop,
    localPin,
    teacher,
    logOut,
    checklist,
    speechBubble,
];

const parseAssetUrl = (asset) => {
    const src = asset.toString();
    const lastIndex = src.lastIndexOf('/');
    const value = src.substring(lastIndex + 1);

    return value.split('.')[0].concat('.svg');
};

export const getAsset = (iconName) => assets.find((asset) => iconName === parseAssetUrl(asset));

export const OLC_VERSION = process.env.REACT_APP_VERSION;
window.OLC_VERSION = OLC_VERSION;

export const getPortalBaseUrl = () => process.env.REACT_APP_API_PORTAL_URL;

export const getIdpBaseUrl = () => process.env.REACT_APP_API_IDP_URL;

export const getBibBaseUrl = () => process.env.REACT_APP_API_BIB_URL;

export const getOlatExamsBaseUrl = () => process.env.REACT_APP_API_OLAT_EXAMS_URL;

export const getLanguageCourseBaseUrl = () => process.env.REACT_APP_API_LANGUAGE_COUSE_OLAT_EXAMS_URL;

export const getExamsBaseUrl = () => process.env.REACT_APP_API_EXAMS_URL;

export const getOlcReportingBaseUrl = () => process.env.REACT_APP_OLC_REP_URL;

export const getAppBaseUrl = () => (
    window.location.href.includes('schuelerhilfe-olc.de')
        ? process.env.REACT_APP_BASEURL_VIDIS + process.env.REACT_APP_BASENAME
        : process.env.REACT_APP_BASEURL + process.env.REACT_APP_BASENAME
);

export const getOLCSurveyUrl = () => process.env.REACT_APP_OLC_SURVEY_URL;

export const getWikisBannerUrl = () => process.env.REACT_APP_WIKIS_BANNER_URL;

export const getWikisViewPixelUrl = () => process.env.REACT_APP_WIKIS_VIEW_PIXEL_URL;

export const isBlank = (value) => value === null || value === undefined || value.toString().trim() === '';

export const isEmpty = (value) => value === null || value === undefined || value.length < 1;

const VIDISAuthUrl = () => process.env.REACT_APP_VIDIS_AUTH_URL;
const VIDISClientId = () => process.env.REACT_APP_VIDIS_CLIENT_ID;
const VIDIScope = () => process.env.REACT_APP_VIDIS_SCOPE;
const VIDISResponseType = () => process.env.REACT_APP_VIDIS_RESPONSE_TYPE;

export const handleUserWithoutEmailAddress = (email) => {
    if (!email.includes('@') && email.startsWith('vidis-nutzer')) {
        return `${email}@vidis-olc.de`;
    }

    if (!email.includes('@') && email.startsWith('lisa-nutzer')) {
        return `${email}@lisa-olc.de`;
    }

    return email;
};

export const getVIDISAuthUrl = () => (
    `${VIDISAuthUrl()}?client_id=${VIDISClientId()}`
    + `&redirect_uri=${encodeURIComponent(getAppBaseUrl().concat('/'))}`
    + `&scope=${VIDIScope()}&response_type=${VIDISResponseType()}`
);

export const componentLoader = (lazyComponent, attemptsLeft = 5) => {
    const result = new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // let us retry after 200 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 200);
            });
    });
    return result;
};

const checkToken = (token) => !!token;

// check if app is running in dev mode
export const inDevelopment = () => process.env.NODE_ENV === 'development';

export const getQueryToken = () => {
    const parsedLocationSearch = queryString.parse(window.location.search);
    const { token } = parsedLocationSearch;
    return checkToken(token) ? token : null;
};

const getTokenPayload = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return new Error('invalid token');
    }
};

export const createCookieStringFromToken = (token, remove = false) => {
    const tokenPayload = getTokenPayload(token);
    const expires = !!remove ? new Date(0) : new Date(tokenPayload.exp * 1000);
    return `username=${tokenPayload.sub}; expires=${expires}; path=/`;
};

export const isTokenPayloadValid = (token) => {
    const tokenPayload = getTokenPayload(token);
    return tokenPayload.message !== 'invalid token';
};

export const getLongestValidToken = (tokenA, tokenB) => (
    moment(getTokenPayload(tokenA).exp * 1000).isAfter(moment(getTokenPayload(tokenB).exp * 1000))
        ? tokenA
        : tokenB
);

export const getExpiryTsFromToken = (token) => getTokenPayload(token || storage.getItem(LOGIN_TOKEN_KEY)).exp;

export const getUserIdFromToken = (token = storage.getItem('token')) => getTokenPayload(token).userid;

export const getUserRoleFromToken = (token) => {
    try {
        return getTokenPayload(token).role;
    } catch (e) {
        return new Error('invalid token');
    }
};

export function getEmailFromToken(token) {
    try {
        return getTokenPayload(token).sub;
    } catch (e) {
        return new Error('invalid token');
    }
}

export function getServicesFromToken(token) {
    try {
        return getTokenPayload(token).services;
    } catch (e) {
        return new Error('invalid token');
    }
}

export function getUserTypeFromToken(token) {
    try {
        return getTokenPayload(token).userType;
    } catch (e) {
        return new Error('invalid token');
    }
}

export function getActiveAboNamesFromToken(token) {
    try {
        return getTokenPayload(token).activeAbos;
    } catch (e) {
        return new Error('invalid token');
    }
}

function isLisaUrl() {
    const url = window.location.href;
    const lastPath = url.substring(url.lastIndexOf('/') + 1);
    return lastPath.startsWith('lisa');
}

export function isVidisUrl() {
    const url = window.location.href;
    const lastPath = url.substring(url.lastIndexOf('/') + 1);
    return lastPath.startsWith('vidis') || url.indexOf('schuelerhilfe-olc.de') > 0;
}

export function isVidisUser() {
    const localToken = localStorage.getItem('token');
    const payload = getTokenPayload(localToken);
    return !!localToken && !!payload && payload.userType === 'Vidis';
}

export function getLogoutUrl(isExternal) {
    const localToken = localStorage.getItem('token');
    let logoutUrl = '/logout';
    if (!!localToken && getTokenPayload(localToken).userType === 'SA' && isExternal) {
        logoutUrl = '/lisa-sachsen-anhalt-logout';
    } else if (isVidisUser() && isExternal) {
        logoutUrl = '/vidis-logout';
    }

    return logoutUrl;
}

export function getLoginUrl() {
    const localToken = localStorage.getItem('token');
    return (!!localToken && getTokenPayload(localToken).userType === 'SA') || isLisaUrl()
        ? '/lisa-sachsen-anhalt-login'
        : '/vidis-login';
}

export function isUserExternal() {
    const localToken = localStorage.getItem('token');
    const payload = getTokenPayload(localToken);
    return (!!localToken && (payload.userType === 'SA' || payload.userType === 'Vidis'))
        || (isLisaUrl() || isVidisUrl());
}

export const isTokenExpired = (token) => {
    const tokenPayload = getTokenPayload(token);

    if (!tokenPayload || !tokenPayload.exp) {
        return true;
    }

    return moment().isAfter(moment(tokenPayload.exp * 1000));
};

export const getUserData = (user) => ({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
    postalCode: user.postalCode,
    birthdate: user.birthdate,
    classlevel: user.classlevel,
    typeOfSchool: user.typeOfSchool,
    policiesAccepted: user.policiesAccepted,
    receiveNewsletter: user.receiveNewsletter,
    icasId: user.icasId,
    userRoleId: user.userRole.id,
});

export const getGA4Data = () => {
    const today = new Date();
    const currentDate = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
    const currentTime = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    return {
        page_path: window.location.pathname,
        date: currentDate,
        time: currentTime,
    };
};

export const loadedInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export function redirectToNotFoundPage() {
    const origin = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.location.replace(`${origin}${process.env.REACT_APP_BASENAME}/404`);
}

/*
    if changes are made in these (replace...) methods please bring these changes also in bib-backend
    because otherwise it can happen that the redirect does not work anymore properly.
    It could lead to unnecessary redirects.
*/
export const replaceNonWordCharacters = (text) => (
    text.toString().toLowerCase()
        .replace(/ä/g, 'ae') // Remove all non-word chars
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
);

export const replaceMultipleSlashes = (text) => (
    text.toString().toLowerCase()
        .replace(/\/\/+/g, '/')
);

export const replaceNotAllowedSpecialChars = (text) => (
    text.toString().toLowerCase()
        .replace(/[^a-zA-Z0-9-/]/g, '') // replaces all spezial chars except of - and /
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
);

export const slugify = (text) => {
    const a = 'àáâèéëêìíïîòóôùúûñçÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;=';
    const b = 'aaaeeeeiiiiooouuuncyoarsnpwgnmuxzh-------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return replaceNotAllowedSpecialChars(replaceNonWordCharacters(text.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
        .replace(/&/g, '-und-') // Replace & with 'and'
        .replace(/\+/g, '-plus-') // Replace plus with -plus-
        .replace(/„|“/g, '-') // Replace german quotes
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .trim()));
};

/**
 * Removes all query parameters that match the given parameter including their values, without touching the remaining
 * parameters.
 * The history state will be replaced afterwards (not pushed as it is not intended when removing query params).
 *
 * @param {*} param the query parameter to remove in text representation
 */
export function removeParam(param) {
    // Check if param exists before executing.
    if (/[?&]/ + param + /=/.test(document.location.search)) {
        const url = document.location.href;
        const urlQueryString = (url.indexOf('?') !== -1) ? url.split('?')[1] : '';
        const paramsArrray = urlQueryString.split('&');

        for (let i = paramsArrray.length - 1; i >= 0; i -= 1) {
            if (paramsArrray[i].split('=')[0] === param) {
                paramsArrray.splice(i, 1);
            }
        }

        // Replace url and history state.
        let urlWithoutGivenParam = url.split('?')[0];
        urlWithoutGivenParam = `${urlWithoutGivenParam}${paramsArrray.length > 0 ? '?' : ''}${paramsArrray.join('&')}`;
        window.history.replaceState('', document.title, urlWithoutGivenParam);
    }
}

export function removeVidisUrlParams(params) {
    if (params.code) {
        removeParam('code');
    }
    if (params.state) {
        removeParam('state');
    }
}

export const getQueryParameters = () => queryString.parse(window.location.search);

export const shouldRedirectToVidisLogin = (authenticated) => {
    if (!authenticated && window.location.href.includes('schuelerhilfe-olc.de')) {
        const { code, session_state: sessionState } = getQueryParameters();
        if (!isBlank(code) && !isBlank(sessionState)) {
            return false;
        }

        switch (window.location.pathname) {
        case `${process.env.REACT_APP_BASENAME}/vidis-login`:
        case `${process.env.REACT_APP_BASENAME}/vidis-datenschutz`:
        case `${process.env.REACT_APP_BASENAME}/impressum`:
            return false;
        default:
            return true;
        }
    }

    if (!!authenticated && window.location.href.includes('schuelerhilfe-olc.de')) {
        switch (window.location.pathname) {
        case `${process.env.REACT_APP_BASENAME}/feedback`:
            return true;
        default:
            return false;
        }
    }

    return false;
};

export const shouldRedirectToOlcStartPage = (authenticated) => {
    if (!!authenticated || window.location.href.includes('schuelerhilfe-olc.de')) {
        return false;
    }

    switch (window.location.pathname) {
    // TODO: complete the list of pages that should be redirected to olc start page if not logged in
    case `${process.env.REACT_APP_BASENAME}/`:
    case `${process.env.REACT_APP_BASENAME}/interaktive-aufgaben`:
    case `${process.env.REACT_APP_BASENAME}/interaktive-aufgabe-verschicken`:
    case `${process.env.REACT_APP_BASENAME}/hausaufgabenhilfe`:
    case `${process.env.REACT_APP_BASENAME}/webinare`:
    case `${process.env.REACT_APP_BASENAME}/freischalten`:
    case `${process.env.REACT_APP_BASENAME}/konto`:
    case `${process.env.REACT_APP_BASENAME}/musterklausuren`:
    case `${process.env.REACT_APP_BASENAME}/abiturvorbereitungsbuecher`:
    case `${process.env.REACT_APP_BASENAME}/online-kurse`:
    case `${process.env.REACT_APP_BASENAME}/digitale-buecher`:
    case `${process.env.REACT_APP_BASENAME}/kira-datenschutz`:
    case `${process.env.REACT_APP_BASENAME}/kira`:
    case `${process.env.REACT_APP_BASENAME}/kira-lehrkraefte`:
        return true;
    default:
        return false;
    }
};

export const shouldRenderSearchAndMenu = () => {
    switch (window.location.pathname) {
    case `${process.env.REACT_APP_BASENAME}/abo-auswaehlen`:
    case `${process.env.REACT_APP_BASENAME}/abos-und-preise`:
    case `${process.env.REACT_APP_BASENAME}/ausprobieren`:
    case `${process.env.REACT_APP_BASENAME}/bezahlung-erfolgreich`:
    case `${process.env.REACT_APP_BASENAME}/bezahlung-fehlgeschlagen`:
        return false;
    default:
        return true;
    }
};

export const getHeaders = (setAuthorization = false) => {

    const header = {
        'Content-Type': 'application/json',
        'Accept-Language': 'de',
        'Cache-Control': 'no-cache, no-store',
    };

    if (setAuthorization) {
        header.Authorization = `Bearer ${storage.getItem('token')}`;
    }

    return new Headers(header);
};

export const getUploadHeaders = (setAuthorization = false) => {
    const header = {
        'Accept-Language': 'de',
        'Cache-Control': 'no-cache, no-store',
    };

    if (setAuthorization) {
        header.Authorization = `Bearer ${storage.getItem('token')}`;
    }

    return new Headers(header);
};

export const getUserLanguage = () => 'de';

export const getLocalizedDateString = (dateString, formatToken) => {

    const currentLocale = getUserLanguage();

    return moment(dateString).locale(currentLocale).format(formatToken);
};

export const getSelectedFaqEntry = (selectedId) => {
    const selectedTopicId = selectedId.split('_')[0];
    return faqData.find((entry) => entry.id === selectedTopicId);
};

export const getOlcReportingSessionId = () => {
    let sessionId = window.sessionStorage.getItem(OLC_SESSION_ID_KEY);
    if (!sessionId) {
        sessionId = `${getUserIdFromToken()}_${Math.round(+new Date() / 1e3)}`;
        window.sessionStorage.setItem(OLC_SESSION_ID_KEY, sessionId);
    }

    return sessionId;
};

export const deleteOlcReportingSessionId = () => {
    window.sessionStorage.removeItem(OLC_SESSION_ID_KEY);
};

export async function trackUserActivity({ category, action, label, value }) {
    return fetch(
        `${getOlcReportingBaseUrl()}/ua/activity`,
        {
            method: 'POST',
            headers: getHeaders(true),
            body: JSON.stringify({ category, action, label, value, sessionId: getOlcReportingSessionId() }),
        },
    );
}

const getConsentServiceIdByName = (serviceName) => {
    // the ids in the following object come from usercentrics and identify the specific service
    const services = {
        'google analytics': 'HkocEodjb7',
        hotjar: 'S1kgcNo_j-m',
        intelliad: 'kxpY--cy2tZJ8r',
    };

    return !!serviceName ? services[serviceName.toLowerCase()] : services['google analytics'];
};

export const consentChanged = (eventConsents, localConsents, serviceName) => {
    const serviceId = getConsentServiceIdByName(serviceName);

    return eventConsents[serviceName] !== localConsents.filter((consent) => consent.id === serviceId)[0]?.status;
};

export const isTrackingAllowed = (serviceName) => {
    if (isUserExternal()) {
        return false;
    }

    if (inDevelopment()) {
        return true;
    }

    // if no service name is set, google analytics is used as the standard
    const serviceId = getConsentServiceIdByName(serviceName);

    const consents = JSON.parse(storage.getItem('uc_settings'))?.services;
    if (!!consents) {
        try {
            const userCentricsConsent = consents.filter((consent) => consent.id === serviceId)[0]?.status;
            const localStorageConsentStatus = !storage.getItem('disableTracking')
                || storage.getItem('disableTracking') === 'false';
            return userCentricsConsent && localStorageConsentStatus;
        } catch (e) {
            // exception while checking consent status
            return false;
        }
    }

    return false;
};

// trackUA: boolean - track user activity with user activities backend
export const trackUserEvent = ({
    trackGA = true,
    trackGA4,
    trackUA,
    category,
    action,
    label,
    value,
    nonInteraction,
    ga4Data,
}) => {
    if (!!trackGA && (!!isTrackingAllowed() || !!inDevelopment())) {
        ReactGA.event({ category, action, label, nonInteraction, value });
    }

    if (!!trackGA4 && (!!isTrackingAllowed() || !!inDevelopment())) {
        ReactGA4.gtag('event', category, { ...ga4Data });
    }

    if (!!trackUA && (!!isTrackingAllowed() || !!inDevelopment())) {
        trackUserActivity({ category, action, label, value });
    }
};

export const pushToDataLayer = ({ event, eventCategory, eventAction, eventLabel }) => {
    if (isTrackingAllowed()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event, eventCategory, eventAction, eventLabel,
        });
    }
};

export const pushToDataLayerGA4 = ({ event, category, ga4Data }) => {
    if (isTrackingAllowed()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event, category, ...ga4Data,
        });
    }
};

export const initializeTracking = () => {
    if (isTrackingAllowed()) {
        ReactGA.initialize(process.env.REACT_APP_GA_ID, {
            debug: inDevelopment(),
            titleCase: false,
            gaOptions: {
                siteSpeedSampleRate: 100,
            },
        });
        ReactGA.set({ anonymizeIp: true });

        if (window.addEventListener) {
            window.addEventListener('error', (e) => {
                if (e.lineno !== 0) {
                    ReactGA.exception({ description: e.message, fatal: true });
                    trackUserEvent({
                        category: GA.CATEGORIES.ERROR,
                        action: GA.ACTIONS.JAVASCRIPT_ERROR,
                        label: `${e.message} in: ${e.filename} on line ${e.lineno}`,
                        nonInteraction: true,
                    });
                }
            });
        }

        return true;
    }

    return false;
};

export const initializeTrackingGA4 = () => {
    if (isTrackingAllowed()) {
        ReactGA4.initialize(process.env.REACT_APP_GA4_ID, {
            gaOptions: {
                siteSpeedSampleRate: 100,
            },
        });
        ReactGA4.set({ anonymizeIp: true });

        if (window.addEventListener) {
            window.addEventListener('error', (e) => {
                if (e.lineno !== 0) {
                    const ga4Data = getGA4Data();
                    trackUserEvent({
                        trackGA: false,
                        trackGA4: true,
                        category: GA.GA4CATEGORIES.ERROR,
                        ga4Data: {
                            interaction_type: GA.ACTIONS.JAVASCRIPT_ERROR,
                            name: `${e.message} in: ${e.filename} on line ${e.lineno}`,
                            page_path: ga4Data.page_path,
                            date: ga4Data.date,
                            time: ga4Data.time,
                        },
                    });
                }
            });
        }
        return true;
    }
    return false;
};

export const disableTracking = () => {
    storage.setItem('disableTracking', true);
};

export const enableTracking = () => {
    storage.removeItem('disableTracking');
};

export const validate = (validation, formValues) => {
    let formErrors = null;

    if (!validation) return formErrors;

    validation.forEach((validationItem) => {
        let isValid = null;
        if (!!validationItem.compare) {
            isValid = validationRules[validationItem.rule](formValues[validationItem.name],
                formValues[validationItem.compare]);
        } else if (!!validationItem.condition) {
            isValid = validationRules[validationItem.rule](formValues[validationItem.name],
                formValues[validationItem.condition] === validationItem.equals);
        } else {
            isValid = validationRules[validationItem.rule](formValues[validationItem.name], validationItem);
        }
        if (!isValid) {
            formErrors = {
                ...formErrors,
                [validationItem.name]: validationItem.message[getUserLanguage().toLowerCase().split(/[_-]+/)[0]],
            };
        }
    });
    return formErrors;
};

export function debounce(func, wait, immediate) {
    /* 'private' variable for instance. The returned function will be able to reference this due to closure.
       Each call to the returned function will share this common timer. */
    let timeout;

    // Calling debounce returns a new anonymous function
    return (...args) => {
        // reference the context and args for the setTimeout function
        const context = this;

        // Should the function be called now? If immediate is true and not already in a timeout then the answer is: Yes
        const callNow = immediate && !timeout;

        /* This is the basic debounce behaviour where you can call this  function several times, but it will only
           execute once [before or after imposing a delay]. Each time the returned function is called,
           the timer starts over. */
        clearTimeout(timeout);

        // Set the new timeout
        timeout = setTimeout(() => {

            /* Inside the timeout function, clear the timeout variable which will let the next execution run when in
            'immediate' mode */
            timeout = null;

            // Check if the function already ran with the immediate flag
            if (!immediate) {
            /* Call the original function with apply. This lets you define the 'this' object as well as the arguments
                (both captured before setTimeout) */
                func.apply(context, args);
            }
        }, wait);

        // Immediate mode and no wait timer? Execute the function..
        if (callNow) {
            func.apply(context, args);
        }
    };
}

export function getReferrer() {
    const referrer = storage.getItem('referrer');

    if (isEmpty(referrer)) {
        return undefined;
    }

    storage.removeItem('referrer');

    if (referrer.startsWith(window.location.origin)) {
        return undefined;
    }

    return referrer || undefined;
}

export function setReactGAPageView(location) {
    /**
     * After user centrics events fired a page-reload is called
     * which leads to a loss of referrer (if existed, e.g. google.com).
     * Therefore, referrer is stored by user centrics events in the storage
     * and reused by google analytics here.
    */
    ReactGA.set({
        page: location,
        location: `${window.location.origin}${window.location.pathname}${window.location.search}`,
        referrer: getReferrer(),
    });
    ReactGA.pageview(location);

    ReactGA4.set({
        page: location,
        location: `${window.location.origin}${window.location.pathname}${window.location.search}`,
        referrer: getReferrer(),
    });
    ReactGA4.send({ hitType: 'pageview', page: location });
}

export const getErrorMessage = (formErrors, name) => {
    if (isBlank(name)) {
        return null;
    }

    return !!formErrors && !!formErrors[name] ? formErrors[name] : null;
};

const containsLowerCase = (value) => value.toUpperCase() !== value;

const containsUpperCase = (value) => value.toLowerCase() !== value;

const containsNumber = (value) => /\d/.test(value);

const hasMinLength = (value, minLength) => value.length >= minLength;

export const passwordHasValidFormat = (enteredPassword) => (
    containsLowerCase(enteredPassword)
    && containsUpperCase(enteredPassword)
    && containsNumber(enteredPassword)
    && hasMinLength(enteredPassword, 8)
);

export const flattenMessages = (nestedMessages, prefix = '') => Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        messages[prefixedKey] = value;
    } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
}, {});

export const objectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const getNavigationLink = (center) => {

    const url = `https://www.google.com/maps?f=d&daddr=${center.street},${center.zip} ${center.city}`
              + `${(!!center.country_code ? `, ${center.country_code}` : '')}&dirflg=d`;

    return url.trim().replace(/\s/gi, '+');
};

// Gets the abbreviation from a weekDay and returns its corresponding full name of the day.
export const renderDayString = (weekDay, formatMessage) => {
    switch (weekDay) {
    case 'MO':
        return formatMessage({ id: 'weekDay.monday' });
    case 'TU':
        return formatMessage({ id: 'weekDay.tuesday' });
    case 'WE':
        return formatMessage({ id: 'weekDay.wednesday' });
    case 'TH':
        return formatMessage({ id: 'weekDay.thursday' });
    case 'FR':
        return formatMessage({ id: 'weekDay.friday' });
    case 'SA':
        return formatMessage({ id: 'weekDay.saturday' });
    case 'SU':
        return formatMessage({ id: 'weekDay.sunday' });
    default:
        return '';
    }
};

export const isMobileDevice = () => {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    }

    return false;
};

export const isIpad = () => (
    navigator.userAgent.match(/iPad/i)
);

export const getMobileType = () => {
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
        return 'ios';
    }

    return 'android';
};

// check if given subject is known corporate subject
export const isKnownSubject = (subject) => corporateSubjects.includes(subject);

export const validEmailAddress = (email) => {
    const re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
    return re.test(String(email).toLowerCase().trim());
};

export const getTokenPayloadFromStorage = () => {
    try {
        const token = storage.getItem('token');
        if (token) {
            return getTokenPayload(token);
        }
        return null;
    } catch (e) {
        return null;
    }
};

export const tokenPayloadContainsSHDomain = (tokenPayload) => {
    if (tokenPayload && tokenPayload.sub) {
        const email = tokenPayload.sub.toLowerCase();
        return (email.endsWith('@schuelerhilfe.de')
            || email.endsWith('@schuelerhilfe.com')
            || email.endsWith('@schuelerhilfe-online.de'));
    }
    return false;
};

export const navigateTo = (urlPart) => {
    if (urlPart.indexOf('http://') > -1
        || urlPart.indexOf('https://') > -1
        || urlPart.indexOf('.pdf') > -1
        || urlPart.indexOf('mailto:') > -1) {
        // navigate to external URL
        window.location = urlPart;
    } else {
        // navigate to internal URL
        history.push(urlPart);
    }
};

const getSubjectValueForName = (name) => {
    const subjectValues = [
        {
            name: 'Mathe',
            value: 'math',
        },
        {
            name: 'Mathematik',
            value: 'math',
        },
        {
            name: 'Deutsch',
            value: 'german',
        },
        {
            name: 'Englisch',
            value: 'english',
        },
        {
            name: 'Französisch',
            value: 'french',
        },
        {
            name: 'Latein',
            value: 'latin',
        },
        {
            name: 'Biologie',
            value: 'biology',
        },
        {
            name: 'Chemie',
            value: 'chemistry',
        },
        {
            name: 'Physik',
            value: 'physics',
        },
    ];

    const result = subjectValues.find((subjectValue) => name.includes(subjectValue.name));

    return !!result ? result.value : result;
};

const getGradeFromExam = (exam) => {
    if (exam.name.includes('Grundschule')) {
        return 'Grundschule';
    }

    if (exam.name.includes('Unterstufe')) {
        return 'Unterstufe';
    }

    if (exam.name.includes('Mittelstufe')) {
        return 'Mittelstufe';
    }

    if (exam.name.includes('Oberstufe')) {
        return 'Oberstufe';
    }

    if (exam.name.includes('1. Lernjahr')) {
        return '1. Lernjahr';
    }

    if (exam.name.includes('2. Lernjahr')) {
        return '2. Lernjahr';
    }

    if (exam.name.includes('3. Lernjahr')) {
        return '3. Lernjahr';
    }

    if (exam.name.includes('4. Lernjahr')) {
        return '4. Lernjahr';
    }

    if (exam.name.includes('Lernjahr 1')) {
        return 'Lernjahr 1';
    }

    if (exam.name.includes('Lernjahr 2')) {
        return 'Lernjahr 2';
    }

    if (exam.name.includes('Lernjahr 3')) {
        return 'Lernjahr 3';
    }

    if (exam.name.includes('Lernjahr 4')) {
        return 'Lernjahr 4';
    }

    return 'Grundschule';
};

const cleanupExamName = (name, subject, grade) => {
    let result = name.slice('Fachcheck: '.length);

    if (subject === 'math') {
        if (name.includes('Mathematik')) {
            result = result.slice('Mathematik - '.length);
        } else {
            result = result.slice('Mathe - '.length);
        }
    } else if (subject === 'german') {
        result = result.slice('Deutsch - '.length);
    } else if (subject === 'english') {
        result = result.slice('Englisch - '.length);
    } else if (subject === 'french') {
        result = result.slice('Französisch - '.length);
    } else if (subject === 'latin') {
        result = result.slice('Latein - '.length);
    } else if (subject === 'biology') {
        result = result.slice('Biologie - '.length);
    } else if (subject === 'chemistry') {
        result = result.slice('Chemie - '.length);
    } else if (subject === 'physics') {
        result = result.slice('Physik - '.length);
    }

    result = result.slice(`${grade} - `.length);

    return result.slice(0, -8);
};

export const getDetailsFromExam = (exam) => {
    const subject = getSubjectValueForName(exam.name);
    const grade = getGradeFromExam(exam);
    const level = exam.name.slice(-8);
    const name = cleanupExamName(exam.name, subject, grade).trim();

    return {
        id: exam.exam_id,
        subject,
        grade,
        name,
        level,
        category: exam.category,
        startDate: exam.start_date,
        endDate: exam.end_date,
        isCourse: exam.isCourse,
    };
};

export const getDetailsFromTopic = (topic, sortedSubjects, sortedGrades) => {
    const topicSubject = sortedSubjects.find((subject) => subject.id === topic.subjectId);
    const topicGrade = sortedGrades.find((grade) => grade.id === topic.gradeId);

    return {
        id: topic.id,
        subject: getSubjectValueForName(topicSubject.name),
        grade: topicGrade.name,
        name: topic.name,
    };
};

export const getShortString = (input) => {
    if (input.length <= 10) return input;
    const first = input.substr(0, 10);
    const last = input.substr(input.length - 10);

    return `${first}...${last}`;
};

export function getPromotionEndDate(nationState) {
    switch (nationState) {
    case 'mecklenburg-vorpommern':
    case 'hamburg':
    case 'berlin':
    case 'brandenburg':
    case 'schleswig-holstein':
    case 'nrw':
    case 'saarland':
    case 'rheinland-pfalz':
    case 'hessen':
    case 'bremen':
    case 'niedersachsen':
    case 'sachsen-anhalt':
    case 'sachsen':
    case 'thueringen':
    case 'bayern':
    case 'baden-wuerttemberg':
    case 'deutschland':
    case 'auslandsschulen':
        return '11.04.2021';
    default:
        return '';
    }
}

export function getRegistrationType(state) {
    switch (state.toLowerCase()) {
    case 'bremen':
        return 'STATE_BREMEN';
    case 'sachsen':
        return 'STATE_SACHSEN';
    case 'schleswig-holstein':
        return 'STATE_SCHLESWIG_HOLSTEIN';
    case 'berlin':
        return 'STATE_BERLIN';
    case 'bayern':
        return 'STATE_BAYERN';
    case 'thueringen':
        return 'STATE_THUERINGEN';
    case 'nrw':
        return 'STATE_NRW';
    case 'sachsen-anhalt':
        return 'STATE_SACHSEN_ANHALT';
    case 'saarland':
        return 'STATE_SAARLAND';
    case 'rheinland-pfalz':
        return 'STATE_RHEINLAND_PFALZ';
    case 'mecklenburg-vorpommern':
        return 'STATE_MECKPOM';
    case 'hessen':
        return 'STATE_HESSEN';
    case 'hamburg':
        return 'STATE_HAMBURG';
    case 'brandenburg':
        return 'STATE_BRANDENBURG';
    case 'baden-wuerttemberg':
        return 'STATE_BADEN_WUERTTEMBERG';
    case 'niedersachsen':
        return 'STATE_NIEDERSACHSEN';
    case 'deutschland':
        return 'STATE_GERMANY';
    case 'auslandsschulen':
        return 'STATE_FOREIGN';
    case 'olc_gratis':
        return 'SPECIAL_TRIAL';
    case 'sms_trial':
        return 'SMS_TRIAL';
    case 'whatsapp':
        return 'WHATSAPP';
    case 'beraten-und-verkaufen':
        return 'CONSULT_AND_SELL';
    case 'fresh-up-beraten-und-verkaufen':
        return 'FRESH_UP_CONSULT_AND_SELL';
    case 'einstellungstest':
        return 'EMPLOYEE_TEST';
    case 'kunden-schueler-eltern-test':
        return 'CUSTOMER_PUPIL_PARENT_TEST';
    case 'fresh-up-kundengespraeche':
        return 'FRESH_UP_CUSTOMER_PUPIL_PARENT_TEST';
    case 'nhl-einstellung-einarbeitungstest':
        return 'NHL_RECRUITMENT_ONBORDING_TEST';
    case 'lisa-sachsen-anhalt':
        return 'LISA_SACHSEN_ANHALT';
    case 'at-start-up-trainingstest':
        return 'AT_START_UP_TRAINING_TEST';
    case 'de-start-up-trainingstest':
        return 'DE_START_UP_TRAINING_TEST';
    case '14-days-free':
        return 'FOURTEEN_DAYS_TRAIL';
    default:
        return '';
    }
}

export const getRegistrationTemplateData = (path) => {
    const template = {
        beratenUndVerkaufenTest: {
            pathName: path,
            showCenter: true,
            templateName: 'consultAndSellTest',
        },
        beratenUndVerkaufenFranchiseTest: {
            pathName: path,
            showCenter: true,
            templateName: 'freshUpConsultAndSellTest',
            templateType: 'franchise',
        },
        einstellungTest: {
            pathName: path,
            showCenter: false,
            templateName: 'employeeTest',
        },
        kundenSchülerElternTest: {
            pathName: path,
            showCenter: true,
            templateName: 'customerPupilParentTest',
        },
        kundenSchülerElternFranchiseTest: {
            pathName: path,
            showCenter: true,
            templateName: 'freshUpCustomerPupilParentTest',
            templateType: 'franchise',
        },
        nhlEinstellungEinarbeitungTest: {
            pathName: path,
            showCenter: true,
            templateName: 'nhlRecruitmentOnboardingTest',
        },
        startUpTrainingTest: {
            pathName: path,
            showCenter: true,
            templateName: 'startUpTrainingTest',
            templateType: path === 'at-start-up-trainingstest' ? 'atFranchise' : 'franchise',
        },
    };

    switch (path) {
    case 'beraten-und-verkaufen':
        return template.beratenUndVerkaufenTest;
    case 'fresh-up-beraten-und-verkaufen':
        return template.beratenUndVerkaufenFranchiseTest;
    case 'einstellungstest':
        return template.einstellungTest;
    case 'kunden-schueler-eltern-test':
        return template.kundenSchülerElternTest;
    case 'fresh-up-kundengespraeche':
        return template.kundenSchülerElternFranchiseTest;
    case 'nhl-einstellung-einarbeitungstest':
        return template.nhlEinstellungEinarbeitungTest;
    case 'at-start-up-trainingstest':
    case 'de-start-up-trainingstest':
        return template.startUpTrainingTest;
    default:
        return 'default';
    }
};

export const testCenters = Object.freeze([9992, 9993, 9994, 9995, 9996, 9997, 9998, 9999]);

export const importAllImages = (path) => {
    const images = {};

    path.keys().forEach((item) => { images[item.replace('./', '')] = path(item); });

    return images;
};

const isMobile = isMobileDevice();

export function selectImageWRTDeviceAndWebp({
    standard, standardMobile, webp, webpMobile, supportsWebP,
}) {
    if (isMobile) {
        if (supportsWebP) {
            return webpMobile;
        }
        return standardMobile;

    } if (supportsWebP) {
        return webp;
    }
    return standard;

}

export const getTopLevelDomain = () => (
    window.location.host.split('.').reverse()[0].replace(/[^a-zA-Z]/g, '') || '').toLowerCase();

export const isGermanTopLevelDomain = () => getTopLevelDomain() === 'de';
export const isAustrianTopLevelDomain = () => getTopLevelDomain() === 'at';

export const isAtFranchiseCenter = (center) => (center.franchise && ((center.number > 1500 && center.number < 1999)
    || (center.number > 2501 && center.number < 2999)));

export const isAiTutorTeacher = () => window.location.href.includes('/kira-lehrkraefte');

export const loadScript = (url, callback) => {
    const script = document.createElement('script');
    script.src = url;
    if (callback) {
        script.onload = callback;
    }
    document.head.appendChild(script);
};

export const configureMathJax = () => {
    window.MathJax.Hub.Config({
        config: ['MMLorHTML.js'],
        jax: ['input/TeX', 'input/MathML', 'output/HTML-CSS', 'output/NativeMML', 'output/PreviewHTML'],
        extensions: [
            'tex2jax.js',
            'mml2jax.js',
            'MathMenu.js',
            'MathZoom.js',
            'fast-preview.js',
            'AssistiveMML.js',
        ],
        TeX: {
            extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js', 'color.js'],
        },
        'HTML-CSS': {
            linebreaks: { automatic: true },
            imageFont: null,
        },
        SVG: {
            linebreaks: { automatic: true },
            imageFont: null,
        },
    });
    window.DynamicMJ = {
        update: () => {
            window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, 'body']);
        },
    };
    window.DynamicMJ.update();
};

export const checkMathjax = () => {
    // only load mathjax on content pages where formulas could be present
    if ((document.body.innerHTML.includes('\\[') && document.body.innerHTML.includes('\\]'))
            || (document.body.innerHTML.includes('\\(') && document.body.innerHTML.includes('\\)'))) {
        loadScript('https://mathjax.schuelerhilfe-online.de/MathJax.js', () => configureMathJax());
        window.mathjaxScriptLoadded = true;
    }
};
