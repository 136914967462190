// Network.
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getPortalBaseUrl, getHeaders, getIdpBaseUrl } from '../../utils/utils';

import { ERROR } from '../../utils/constants';

// Actions.
import {
    AI_TUTOR_LOGIN,
    AI_TUTOR_REGISTER,
    UPDATE_USER_AI_TUTOR_ID,
    UPDATE_USER_KIRA_POLICIES,
    setAiTutorLoginToken,
    setAiTutorTeacherLoginToken,
    updateUserAiTutorId,
} from './actions';
import { addMessage } from '../../containers/MessageModal/actions';
import { setUser } from '../Account/actions';

export function* handleLogin(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/ai-tutor/authenticate`;
    const headers = getHeaders(true);

    const { payload, intl } = action;
    const { kiraInstance } = payload;

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });
        if (data && data.token) {
            if (kiraInstance === 'aiTutorTeacher') {
                yield put(setAiTutorTeacherLoginToken(data.token));
            } else {
                yield put(setAiTutorLoginToken(data.token));
            }
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'aiTutor.loginError' })));
    }
}

export function* handleRegister(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/ai-tutor/register`;
    const headers = getHeaders(true);

    const { payload, intl } = action;

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });
        if (data && data.id) {
            yield put(updateUserAiTutorId(payload.userId, data.id, payload.kiraInstance, intl));
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'aiTutor.registrationError' })));
    }
}

export function* handleUpdateAiTutorId(action) {
    const { payload } = action;
    const { userId, aiTutorId, kiraInstance } = payload;

    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/${userId}/aiTutorId?aiTutorId=${aiTutorId}&kiraInstance=${kiraInstance}`;
    const headers = getHeaders(true);

    try {
        const data = yield call(fetchSaga, url, { method: 'PUT', headers });
        if (data) {
            yield put(setUser(data));
        }
    } catch (e) {
        yield put(addMessage(ERROR, e.message));
    }
}

export function* handleUpdateUser(action) {
    const { userId, hasKiraPoliciesAccepted } = action.payload;

    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/${userId}/kiraPolicies?hasKiraPoliciesAccepted=${hasKiraPoliciesAccepted}`;
    const headers = getHeaders(true);

    try {
        const data = yield call(fetchSaga, url, { method: 'PUT', headers });
        if (data) {
            yield put(setUser(data));
        }
    } catch (e) {
        yield put(addMessage(ERROR, e.message));
    }
}

export function* waitForUserWasLoggedIn() {
    yield takeEvery(AI_TUTOR_LOGIN, handleLogin);
}

export function* waitForUserWasRegistered() {
    yield takeEvery(AI_TUTOR_REGISTER, handleRegister);
}

export function* waitForUserAiTutorIdWasUpdated() {
    yield takeEvery(UPDATE_USER_AI_TUTOR_ID, handleUpdateAiTutorId);
}

export function* waitForUserWasUpdated() {
    yield takeEvery(UPDATE_USER_KIRA_POLICIES, handleUpdateUser);
}

export const aiTutorSaga = [
    waitForUserWasLoggedIn(),
    waitForUserWasRegistered(),
    waitForUserAiTutorIdWasUpdated(),
    waitForUserWasUpdated(),
];
